<template>
  <div
    class="relative w-full h-full flex flex-col lg:flex-row px-4 py-8 lg:p-10 lg:space-x-8 space-y-8 lg:space-y-0 justify-center items-center"
  >
    <vac :end-time="new Date('2022-04-29T10:00:00Z')">
      <template v-slot:process="{ timeObj }">
        <div
          class="absolute inset-x-0 top-0 h-8 bg-yellow-300 text-black font-semibold text-sm flex justify-center items-center"
        >
          <span>
            {{ timeObj.d === '1' ? '1 day' : timeObj.d + ' days' }}
            {{ timeObj.h === '01' ? '01 hour' : timeObj.h + ' hours' }}
            {{ timeObj.m === '01' ? '01 minute' : timeObj.m + ' minutes' }}
            {{ timeObj.s === '01' ? '01 second' : timeObj.s + ' seconds' }}
          </span>
        </div>
      </template>
      <template v-slot:finish> </template>
    </vac>
    <div class="mt-8">
      <img src="../assets/intro_01.png" alt="" />
    </div>
    <div class="pt-4">
      <img src="../assets/intro_02.png" alt="" />
    </div>
  </div>
</template>

<route>
{
  "meta": {
    "auth": false
  }
}
</route>

<script>
export default {
  name: 'Index',
  layout: 'intro',
  components: {}
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.intro {
  width: 460px;
  height: auto;
  max-width: 100%;
  margin-top: 40px;
}
</style>
